@import url('./global.css');

.dropdown-menu {
  background: var(--tertiary-color);
  width: 10rem;
  position: absolute;
  top: 5rem;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: var(--tertiary-color);
  cursor: pointer;
}

.dropdown-menu li:hover {
  filter: brightness(125%);
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  text-decoration: none;
  color: var(--primary-color);
  padding: 1rem;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
