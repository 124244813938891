@import url('./global.css');

/* Entire page */
.services.page {
  gap: 15vh;
  padding-bottom: 10vh;
}

/* ====== Title card for page ====== */
.title-container {
  background-image: url(../img/stars.avif);
  background-color: #000000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 17rem;
  min-width: 0;
  padding-top: 5rem;
  padding-bottom: 3.5rem;
  gap: 5rem;
  max-width: 100%;
}
.services.title {
  font-size: 3.5rem;
  letter-spacing: 0.25rem;
  border: 0.35rem var(--primary-color) solid;
  padding: 1rem 2rem;
}
.service-option-links {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.service-option-link {
  text-decoration: none;
  display: flex;
  border: 0.2rem var(--primary-color) solid;
  padding: 0.5rem 1rem;
  background: none;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.25rem;
}
.service-option-link:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: var(--hf-background-color);
  scale: 1.2;
  transition: all 0.3s ease-out;
}

/* ====== Link to area on page ====== */
.anchor {
  display: block;
  position: relative;
  top: -10rem;
  visibility: hidden;
}

/* ====== Service Options ====== */
.service-option {
  display: flex;
  flex-direction: row;
  border-radius: 2rem;
  width: 60rem;
  min-width: 0;
  justify-content: flex-end;
  height: 27rem;
  align-self: center;

  /* Background image global settings */
  background-color: var(--hf-background);
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
}
.service-option>.content {
  display: flex;
  color: var(--primary-color);
  background: var(--hf-background);
  /* border-left: 0.5rem solid var(--primary-color); */
  border: 0.5rem solid var(--primary-color);
  border-radius: 1.5rem;
  width: 30rem;
}
.service-option>.content>.title {
  transform: rotate(-90deg) translateY(-212.5%);
  display: flex;
  align-self: center;
  border: 0.5rem solid var(--primary-color);
  background: inherit;
  padding: 0 1.5rem;
  letter-spacing: 0.25rem;
}
.service-option>.content>.info {
  padding: 4rem;
  margin-left: -17rem;
}

/* ====== Hosting ====== */
.hosting.service-option {  
  background-image: url(../img/server-woman.avif);
  background-position: -9rem;
}
.hosting.service-option>.content>.info {
  margin-left: -17rem;
}

/* ====== Development ====== */
.development.service-option {  
  background-image: url(../img/computer-table.avif);
}
.development.service-option>.content>.title {
  font-size: 2.25rem;
  transform: rotate(-90deg) translateY(-310%);
}
.development.service-option>.content>.info {
  margin-left: -21rem;
}

/* ====== Migration ====== */
.migration.service-option {  
  background-image: url(../img/squares.avif);
}
.migration.service-option>.content>.title {
  font-size: 2.75rem;
  transform: rotate(-90deg) translateY(-255%);
}
.migration.service-option>.content>.info {
  margin-left: -19rem;
}

/* ====== Consulting ====== */
.consulting.service-option {  
  background-image: url(../img/computer-talk.avif);
}
.consulting.service-option>.content>.title {
  font-size: 2.75rem;
  transform: rotate(-90deg) translateY(-275%);
}
.consulting.service-option>.content>.info {
  margin-left: -21rem;
}

/* ====== Tech Support ====== */
.tech-support.service-option {  
  background-image: url(../img/tech-support.avif);
  background-position: -8.5rem;
}
.tech-support.service-option>.content>.title {
  font-size: 2.35rem;
  transform: rotate(-90deg) translateY(-160%);
}
.tech-support.service-option>.content>.info {
  margin-left: -19rem;
}

/* ====== Mobile display settings ====== */
@media screen and (max-width: 960px) {
  /* Service links */
  .service-option-links {
    flex-direction: column;
    min-width: 12.5rem;
  }  
  .service-option-link {
    min-width: 100%;
    justify-content: center;
  }

  /*Service Options*/
  .service-option {
    flex-direction: column;
    width: 80vw;
    min-width: 20rem;
    max-width: 35rem;
    height: 30rem;
    min-height: 25rem;
    max-height: 25rem;
    border-radius: 1.5rem;
  
    /* Background image global settings */
    background-position: top;
  }
  .service-option>.content {
    flex-direction: column;
    text-align: center;
    min-height: 25vh;
    width: 80vw;
    min-width: 20rem;
    max-width: 35rem;
  }
  .service-option>.content>.title {
    transform: rotate(0deg) translateY(-55%);
    place-content: center;
    width: 80vw;
    min-width: 20rem;
    max-width: 35rem;
    font-size: 150%;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  .service-option>.content>.info {
    margin: 0;
    padding-bottom: 3rem;
    margin-top: -3.5rem;
  }

  /* Hosting */
  .hosting.service-option {
    background-position: center;
    background-position-y: -2rem;
  }
  .hosting.service-option>.content>.info {
    padding-bottom: 4rem;
    margin: 0;
    margin-top: -3.5rem;
  }

  /* Development */
  .development.service-option {
    background-position: top;
    background-position-y: -1.85rem;
  }
  .development.service-option>.content>.title {
    transform: rotate(0deg) translateY(-55%);
    font-size: 150%;
  }
  .development.service-option>.content>.info {
    padding-bottom: 4rem;
    margin: 0;
    margin-top: -3.5rem;
  }

  /* Migration */
  .migration.service-option {
    background-position: center;
    background-position-y: -10rem;
    background-size: cover;
  }
  .migration.service-option>.content>.title {
    transform: rotate(0deg) translateY(-55%);
    font-size: 150%;
  }
  .migration.service-option>.content>.info {
    padding-bottom: 4rem;
    margin: 0;
    margin-top: -3.5rem;
  }

  /* Consulting */
  .consulting.service-option {
    background-position: center;
    background-position-y: 0rem;
  }
  .consulting.service-option>.content>.title {
    transform: rotate(0deg) translateY(-55%);
    font-size: 150%;
  }
  .consulting.service-option>.content>.info {
    padding-bottom: 4rem;
    margin: 0;
    margin-top: -3.5rem;
  }

  /* Consulting */
  .tech-support.service-option {
    background-position: center;
    background-position-y: -3.05rem;
  }
  .tech-support.service-option>.content>.title {
    transform: rotate(0deg) translateY(-55%);
    font-size: 150%;
  }
  .tech-support.service-option>.content>.info {
    padding-bottom: 4rem;
    margin: 0;
    margin-top: -3.5rem;
  }
}