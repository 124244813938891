@import url('./global.css');

.welcome-container {
  background-image: url(../img/desert-mountains.avif);
  background-color: #000000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  width: 100vw;
  align-self: flex-start;
  height: auto;
}

.welcome-message {
  background: var(--hf-background);
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 45rem;
  border-bottom-right-radius: 15rem;
  padding: 3rem 10rem 5rem 3rem;
  gap: 2rem;
  max-width: 100%;
  border-right: 0.4rem var(--tertiary-color) solid;
}

.title {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary-color);
}

.message {
  color: var(--secondary-color);
  font-size: 1.25rem;
}

@media screen and (max-width: 960px) {
  .welcome-container {
    background-image: url(../img/desert-mountains.avif);
    background-color: var(--hf-background-color);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: translate3d(0px, 0px, 0px);
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 0;
    padding-top: 15rem;
  }

  .welcome-message {
    background: var(--hf-background);
    height: auto;
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;
    border-bottom-right-radius: 0rem;
    padding: 3rem;
    gap: 2rem;
    flex: 0 2 auto;
    border-right: none;
  }
}
