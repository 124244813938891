@import url('./global.css');

:root {
  --form-width: 35rem;
}

.contact.page {
  margin-top: calc(var(--header-height) * 1.5);
}

.contact-section {
  background-image: url('../img/waves.avif');
  background-color: #000000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90vw;
}

form {
  display: flex;
  flex-direction: column;
  background-color: #1f2937c5;
  gap: 2rem;
  padding: 2.5rem;
  width: var(--form-width);
  min-width: 0;
  height: auto;
  place-items: center;
}

form > .title {
  align-self: flex-start;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

form > input {
  padding: 0.75rem 1.5rem;
  width: calc(var(--form-width) - 5rem);
  border: none;
  border-radius: 0;
  border-bottom: 0.125rem var(--primary-color) solid;
  background-color: inherit;
  color: var(--primary-color);
  max-width: 100%;
}

form > input:focus:invalid {
  outline: none;
  border-bottom: 0.125rem red solid;
  background-color: inherit;
}

form > input:focus-visible {
  outline: none;
  border-bottom: 0.125rem var(--primary-color) solid;
  background-color: inherit;
}

form > input::placeholder {
  color: var(--secondary-color);
}

.error {
  width: 100%;
  padding: 0;
  margin-top: -2rem;
  font-size: 80%;
  color: var(--primary-color);
  background-color: rgba(153, 0, 0, 0.5);
  border-radius: 0 0 0.32rem 0.32rem;
  box-sizing: border-box;
}

.error.active {
  padding: 0.5rem 1rem;
}

textarea {
  height: 7rem;
  width: calc(var(--form-width) - 5rem);
  max-width: 100%;
  background-color: inherit;
  border: 0.125rem var(--secondary-color) solid;
  padding: 1.25rem;
  border-radius: var(--border-radius);
  color: var(--primary-color);
  font-family: inherit;
  font-size: 0.85rem;
}

textarea::placeholder {
  color: var(--secondary-color);
}

#submit {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 5.625rem;
  max-width: 6.25rem;
  background-color: var(--tertiary-color);
  border: var(--tertiary-color);
  border-radius: var(--border-radius);
  color: var(--primary-color);
  padding: 0.32rem;
  font-weight: bold;
}

#submit:hover {
  filter: brightness(125%);
  cursor: pointer;
}

#submit:active {
  scale: 0.95;
  /*Shrink button when clicked*/
}
