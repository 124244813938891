@import url('./global.css');

.footer {
  color: var(--secondary-color);
  background: var(--hf-background);
  background-color: var(--hf-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  justify-self: flex-end;
}
