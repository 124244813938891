@import url('./global.css');

.container {
  background: var(--hf-background);
  background-color: var(--hf-background-color);
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: bolder;
  padding: 0 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height);
  position: fixed;
  width: 100%;
  z-index: 100;
}

.container .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  background-color: inherit;
  border: none;
  justify-self: start;
  margin-left: 1.25rem;
  cursor: pointer;
  text-decoration: none;
}

.header-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 0.625rem;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.header-item {
  display: flex;
  align-items: center;
  height: var(--header-height);
}

.header-link {
  color: var(--primary-color);
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.header-link:hover {
  background-color: var(--tertiary-color);
  border-radius: 0.25rem;
  transition: all 0.2s ease-out;
}

.services {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.fa-caret-down {
  margin-left: 0.3rem;
}

.fa-bars {
  color: var(--primary-color);
}

.header-menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .header-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: var(--header-height);
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: center;
  }

  .header-menu.active {
    background: var(--hf-background-alt);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .header-link {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .header-link:hover {
    background: red;
    background-color: var(--tertiary-color);
    border-radius: 0.25rem;
  }

  .logo {
    position: absolute;
    top: -1.5rem;
    left: 0.25rem;
    transform: translate(25%, 50%);
  }

  .header-menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--primary-color);
    font-size: 2rem;
  }
}
