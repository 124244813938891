@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

:root {
  font-family: Roboto, sans-serif;
  --hf-background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  --hf-background-alt: #242222;
  --hf-background-color: rgb(31, 41, 55);
  --primary-color: rgb(249, 250, 248);
  --secondary-color: rgb(229, 231, 235);
  --tertiary-color: rgb(246, 167, 56);
  --border-radius: 0.625rem;
  --form-input-width: 25rem;
  --header-height: 5rem;
  background: var(--hf-background);
}

/* CSS RESET */
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  height: auto;
  scroll-behavior: smooth;
  /* background: var(--hf-background); */
  /* filter: brightness(125%); */
}

.body-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  flex-shrink: 0;
  overflow-x: hidden;
}

/* Header and pages */
.above-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: auto;
}

.page {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  align-items: center;
  justify-content: center;
  margin-top: var(--header-height);

  /* Animated load */
  animation: page-load 1s ease-in 50ms;
}

.title-emph {
  border-top: 0.25rem var(--tertiary-color) solid;
  border-bottom: 0.25rem var(--tertiary-color) solid;
}

@keyframes page-load {
  0% {
    opacity: 0;
    scale: 0.995;
  }
  
  100% {
    opacity: 1;
    scale: 1;
  }
}